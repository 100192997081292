
import { Controller } from '@hotwired/stimulus';

const emailRegex = /\S+@\S+\.\S+/;

export default class extends Controller {
  static targets = ['email', 'sendMeResetPassword'];

  validateEmail(e) {
    this.sendMeResetPasswordTarget.classList.add('btn--disabled');
    if (emailRegex.test(e.target.value)) {
      this.sendMeResetPasswordTarget.classList.remove('btn--disabled');
    } else {
      this.sendMeResetPasswordTarget.classList.add('btn--disabled');
    }
  }
}
