import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

const emailRegex = /\S+@\S+/;

export default class extends Controller {
  static targets = ['email', 'password', 'continueButton', 'continueSection', 'passwordSection'];

  static get values() {
    return ['storedEmail'];
  }

  connect() {
    if (emailRegex.test(this.emailTarget.value)) return;

    this.continueSectionTarget.classList.remove('hide');
    this.passwordSectionTarget.classList.add('hide');
  }

  validateEmail(e) {
    if (this.storedEmailValue === this.emailTarget.value.trim()) return;
    if (emailRegex.test(e.target.value)) {
      this.continueButtonTarget.classList.remove('btn--disabled');
      this.storedEmailValue = this.emailTarget.value.trim();
    } else {
      this.continueButtonTarget.classList.add('btn--disabled');
    }
    if (this.continueSectionTarget.classList.contains('hide')) {
      this.passwordSectionTarget.classList.add('hide');
      this.continueSectionTarget.classList.remove('hide');
    }
  }

  continue(e) {
    e.preventDefault();
    const email = this.emailTarget.value;
    const loginContainer = this;

    axios.post('/check_saml_authentication', { user: { email } })
      .then((response) => {
        window.location = response.data.location;
      }).catch(() => {
        loginContainer.continueSectionTarget.classList.add('hide');
        loginContainer.passwordSectionTarget.classList.remove('hide');
        loginContainer.passwordTarget.focus();
      });
  }
}
