import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  preview(e) {
    e.preventDefault();

    if (this.inputTarget.type === 'password') {
      this.inputTarget.type = 'text';
      this.inputTarget.autocomplete = 'off';
    } else {
      this.inputTarget.type = 'password';
      this.inputTarget.autocomplete = 'current-password';
    }
  }
}
