import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['confirmButton', 'alertInfo', 'loadingSpinner'];
  connect() {
    this.confirmButtonTarget.click();
    this.confirmButtonTarget.classList.add('btn--disabled');
  }

  btnClick() {
    const url = new URL(window.location.href);
    url.searchParams.set('auto_confirm_email', 'true');
    fetch(url.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).then(response => {
      if (response.ok) {
        this.loadingSpinnerTarget.classList.add('hidden');
        this.alertInfoTarget.classList.remove('hidden');
      } else {
        console.error("Failed to confirm email!");
      }
    });
  }
}
